import './header.scss';

import { IonButton, IonHeader, IonIcon, IonMenuToggle, IonToolbar } from '@ionic/react';

import React from 'react';
import { menu } from 'ionicons/icons';

const logo = 'https://i.ibb.co/sjkLfst/logo1.png';

const Header: React.FC = () => {
  return (
    <IonHeader className="ionHeaderElement" mode="md">
      <IonToolbar mode="md">
        <div className="header">
          <div className={'menu-icon'}>
            <IonMenuToggle>
              <IonButton fill="clear">
                <IonIcon icon={menu} color="dark" />
              </IonButton>
            </IonMenuToggle>
          </div>
          <div className="logoContainer">
            <img className="logoImage" src={logo} alt="logo" />
          </div>
        </div>
      </IonToolbar>
    </IonHeader>
  );
};

export default Header;

import React, { useContext } from 'react';
import { IonContent, IonButton, IonMenu, IonIcon, IonMenuToggle } from '@ionic/react';
import fire from '../../firebase/base';
import './Menu.styles.scss';
import { home, person, cash, people, notifications, cube, personAdd, logOut, logIn, videocam, flame } from 'ionicons/icons';
import { UserContext } from '../../contexts/UserContext';
import { PopConfirmContext } from '../../contexts/PopConfirm';

export interface MenuProps {
  contentId?: string;
}

const Menu: React.SFC<MenuProps> = props => {
  const { loggedInUser } = useContext(UserContext);
  const { openAlert, setConfirmHandler } = useContext(PopConfirmContext);

  const signOut = () => {
    setConfirmHandler(() => () => fire.auth().signOut());
    openAlert();
  };

  return (
    <IonMenu type="overlay" contentId={props.contentId}>
      <IonContent className="menu-ion-content" fullscreen={true}>
        <div className="Menu-container">
          {loggedInUser && loggedInUser.email ? (
            <>
              <IonMenuToggle autoHide={false}>
                <IonButton mode="md" color="dark" className="navLink" routerLink="/home" fill="clear">
                  <IonIcon mode="md" slot="start" icon={home} />
                  Home
                </IonButton>
              </IonMenuToggle>
              <IonMenuToggle autoHide={false}>
                <IonButton mode="md" color="dark" className="navLink" routerLink="/account" fill="clear">
                  <IonIcon mode="md" slot="start" icon={person} />
                  Account
                </IonButton>
              </IonMenuToggle>
              <IonMenuToggle autoHide={false}>
                <IonButton mode="md" color="dark" className="navLink" routerLink="/subscriptions" fill="clear">
                  <IonIcon mode="md" slot="start" icon={cash} />
                  Subscription
                </IonButton>
              </IonMenuToggle>
              <IonMenuToggle autoHide={false}>
                <IonButton mode="md" color="dark" className="navLink" onClick={signOut} routerLink="/login" fill="clear">
                  <IonIcon mode="md" slot="start" icon={logOut} />
                  Log Out
                </IonButton>
              </IonMenuToggle>
            </>
          ) : (
            <>
              <IonMenuToggle autoHide={false}>
                <IonButton mode="md" color="dark" className="navLink" routerLink="/login" fill="clear">
                  <IonIcon mode="md" slot="start" icon={logIn} />
                  Login
                </IonButton>
              </IonMenuToggle>
              <IonMenuToggle autoHide={false}>
                <IonButton mode="md" color="dark" className="navLink" routerLink="/signup" fill="clear">
                  <IonIcon mode="md" slot="start" icon={personAdd} />
                  Sign Up
                </IonButton>
              </IonMenuToggle>
            </>
          )}
          {loggedInUser && loggedInUser.admin && (
            <>
              <h3>Admin</h3>
              <IonMenuToggle autoHide={false}>
                <IonButton mode="md" color="dark" className="navLink" routerLink="/admin/notifications" fill="clear">
                  <IonIcon mode="md" slot="start" icon={notifications} />
                  Notifications
                </IonButton>
              </IonMenuToggle>
              <IonMenuToggle autoHide={false}>
                <IonButton mode="md" color="dark" className="navLink" routerLink="/admin/packages" fill="clear">
                  <IonIcon mode="md" slot="start" icon={cube} />
                  Packages
                </IonButton>
              </IonMenuToggle>
              <IonMenuToggle autoHide={false}>
                <IonButton mode="md" color="dark" className="navLink" routerLink="/admin/users" fill="clear">
                  <IonIcon mode="md" slot="start" icon={people} />
                  Users
                </IonButton>
              </IonMenuToggle>
              <IonMenuToggle autoHide={false}>
                <IonButton mode="md" color="dark" className="navLink" routerLink="/admin/howtos" fill="clear">
                  <IonIcon mode="md" slot="start" icon={videocam} />
                  How-To Videos
                </IonButton>
              </IonMenuToggle>
              <IonMenuToggle autoHide={false}>
                <IonButton
                  mode="md"
                  color="dark"
                  className="navLink"
                  href="https://console.firebase.google.com/u/1/project/vxmed-c63d6/overview"
                  target="_blank"
                  rel="noopener noreferrer"
                  fill="clear"
                >
                  <IonIcon mode="md" slot="start" icon={flame} />
                  Firebase
                </IonButton>
              </IonMenuToggle>
            </>
          )}
        </div>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;

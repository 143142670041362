import * as Sentry from '@sentry/react';

import React, { useEffect, useState } from 'react';

import { IonSpinner } from '@ionic/react';
import fire from '../firebase/base';

type UserContextProps = {
  loggedInUser: UserObject | undefined;
  setLoggedInUser?: Function;
  logoutUser?: Function;
};

export interface SubscriptionObject {
  product: string;
  nickname: string;
  id: string;
  active: boolean;
}

export interface UserObject {
  id: string;
  collaborator?: boolean;
  education?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  schoolAffiliation?: string;
  admin?: boolean;
  subscriptions?: SubscriptionObject[];
  stripeCustomerID?: string;
}

export const UserContext = React.createContext<UserContextProps>({} as UserContextProps);

export const UserProvider = ({ children }: any) => {
  const [loggedInUser, setLoggedInUser] = useState<UserObject>();
  const [isAppAuthLoading, setIsAppAuthLoading] = useState(false);

  const usersTable = fire.firestore().collection('users');

  useEffect(() => {
    Sentry.setUser({ id: loggedInUser?.id || '' });
  }, [loggedInUser]);

  const userDocObservable = (ID: string) => {
    usersTable.doc(ID).onSnapshot((doc: any) => {
      setLoggedInUser({ ...doc.data(), id: ID });
    });
  };

  useEffect(() => {
    setIsAppAuthLoading(true);
    fire.auth().onAuthStateChanged(user => {
      setIsAppAuthLoading(false);
      if (user) {
        usersTable
          .doc(user.uid)
          .get()
          .then((res: any) => {
            setLoggedInUser({ ...res.data(), id: user.uid });
            userDocObservable(user.uid);
          });
      } else {
        logoutUser();
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logoutUser = () => {
    setLoggedInUser(undefined);
  };

  return (
    <UserContext.Provider
      value={{
        loggedInUser,
        setLoggedInUser,
        logoutUser
      }}
    >
      {isAppAuthLoading ? (
        <div className="loading-container">
          <IonSpinner />
        </div>
      ) : (
        children
      )}
    </UserContext.Provider>
  );
};

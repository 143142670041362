import './DownloadList.style.scss';

import { IonButton, IonSpinner } from '@ionic/react';
import React, { useContext } from 'react';

import LinearProgress from '@material-ui/core/LinearProgress';
import { PackageManagerContext } from '../../contexts/PackageManagerContext';

type DownloadListProps = { selectedPackageIndex: number };

const DownloadList: React.FC<DownloadListProps> = ({ selectedPackageIndex }) => {
  const { checkForPackageUpdates, downloads, availableBinaries } = useContext(PackageManagerContext);

  const checkForUpdates = () => {
    console.log(downloads);
    if (downloads && downloads.length > 0) {
      return;
    }
    checkForPackageUpdates(true);
  };

  return (
    <>
      <IonButton size="small" color="dark" fill="clear" className="version-p" onClick={checkForUpdates}>
        {availableBinaries && availableBinaries.length > 0 && <span>(Current v{availableBinaries[selectedPackageIndex].version})&nbsp;</span>} Check for Updates
      </IonButton>

      {downloads &&
        downloads.length > 0 &&
        downloads!.map((packageObj, index) => {
          return (
            <div key={packageObj.file_name} className="linearProgressBar">
              <h1 className="downloadProgressText">
                {packageObj.downloadProgress && packageObj.downloadProgress > 98 && <IonSpinner />}
                {index === 0 ? (packageObj.downloadProgress && packageObj.downloadProgress > 98 ? 'Installing' : 'Downloading') : 'Waiting'} {packageObj.product_title} v
                {packageObj.version}
                {packageObj.downloadProgress && packageObj.downloadProgress <= 98 && ` - ${packageObj.downloadProgress || 0}%`}
              </h1>
              {packageObj.downloadProgress && packageObj.downloadProgress > 98 && <p className="install-alert">DO NOT RESTART OR CLOSE THE LAUNCHER</p>}
              <LinearProgress variant="determinate" value={packageObj.downloadProgress ? (packageObj.downloadProgress > 98 ? 99 : packageObj.downloadProgress) : 0} />
            </div>
          );
        })}
    </>
  );
};

export default DownloadList;

import * as Sentry from '@sentry/react';

import { createAnimation, setupConfig } from '@ionic/core';

import App from './App';
import { Integrations } from '@sentry/tracing';
import { PackageManagerProvider } from './contexts/PackageManagerContext';
import { PopConfirmProvider } from './contexts/PopConfirm';
import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import { Router } from 'react-router';
import { UserProvider } from './contexts/UserContext';
import { createBrowserHistory } from 'history';

setupConfig({
  mode: 'ios',
  navAnimation: async () => {
    return createAnimation() as any;
  }
});

const trackingID = 'UA-153139435-1';

ReactGA.initialize(trackingID);

const history = createBrowserHistory();

history.listen((location: any) => {
  ReactGA.pageview(location.pathname);
});

Sentry.init({
  dsn: 'https://f02f0316322c4fe0861d6400e256fdaa@o513824.ingest.sentry.io/5616330',
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  environment: process.env.NODE_ENV
});

ReactDOM.render(
  <Router history={history}>
    <UserProvider>
      <PackageManagerProvider>
        <PopConfirmProvider>
          <App />
        </PopConfirmProvider>
      </PackageManagerProvider>
    </UserProvider>
  </Router>,
  document.getElementById('root')
);

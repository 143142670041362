import * as firebase from 'firebase/app';
import 'firebase/auth';

const fire = firebase.initializeApp({
  apiKey: 'AIzaSyBL3S2exzGqkWxPzG6W6yLyobsxZP5RuPM',
  authDomain: 'vxmed-c63d6.firebaseapp.com',
  databaseURL: 'https://vxmed-c63d6.firebaseio.com',
  projectId: 'vxmed-c63d6',
  storageBucket: 'vxmed-c63d6.appspot.com',
  messagingSenderId: '554780617124'
});

export default fire;

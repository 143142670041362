import React, { useState, useContext } from 'react';
import { IonButton, IonCard, IonPage, IonToolbar, IonTitle, IonContent, IonInput, IonItem, IonLabel, IonTextarea, IonCheckbox, IonToast, IonCardHeader } from '@ionic/react';
import axios from 'axios';
import './adminNotifications.scss';
import Header from '../../components/header/header';
import netlifyURI from '../../variables';
import { UserContext } from '../../contexts/UserContext';
import FullPageSpinner from '../../components/full-page-spinner/fullPageSpinner';

const AdminNotifications: React.FC = () => {
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [twilio, setTwilio] = useState(false);
  const [sendgrid, setSendgrid] = useState(true);
  const [sending, setSending] = useState(false);
  const [message, setMessage] = useState('');
  const [toastState, setToastState] = useState(false);

  const { loggedInUser } = useContext(UserContext);

  const onSendgridChange = (params: any) => {
    setSendgrid(params.detail.checked);
  };

  const onTwilioChange = (params: any) => {
    setTwilio(params.detail.checked);
  };

  const onTitleChange = (params: any) => {
    setTitle(params.detail.value);
  };

  const onBodyChange = (params: any) => {
    setBody(params.detail.value);
  };

  const sendNotification = async (event: any) => {
    event.preventDefault();

    setSending(true);

    const requestbody = {
      twilio: twilio,
      sendgrid: sendgrid,
      title: title,
      body: body
    };
    try {
      const url =
        process.env.REACT_APP_LOCAL_LAMBDA_SERVE === 'true' ? 'http://localhost:9000/sendManualNotification.js' : netlifyURI + '.netlify/functions/sendManualNotification';
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const result = await axios.post(url, requestbody);
    } catch (err) {
      setMessage('Send Error: ' + err);
      setToastState(true);
      setSending(false);
    }
    setMessage('Send Successful');
    setToastState(true);
    setSending(false);
  };

  const closeErrorToast = () => {
    setToastState(false);
    setMessage('');
  };

  return (
    <IonPage>
      <IonToast isOpen={toastState} onDidDismiss={closeErrorToast} message={message} cssClass="errorToastUploadModal" position="middle" duration={4000} />

      <Header />
      <IonContent className="screen-bg" fullscreen={true}>
        {loggedInUser && loggedInUser.admin ? (
          <div className="notificationCardContainer">
            <IonCard className="admin__notification_container">
              <IonCardHeader>
                <IonToolbar>
                  <IonTitle>Manual Notification</IonTitle>
                </IonToolbar>
              </IonCardHeader>
              <IonContent className="ion-padding">
                <form onSubmit={sendNotification}>
                  <IonItem>
                    <IonLabel position="floating">Title</IonLabel>
                    <IonInput onIonChange={onTitleChange} disabled={sending} required={true} />
                  </IonItem>
                  <IonItem>
                    <IonLabel position="floating">Description</IonLabel>
                    <IonTextarea onIonChange={onBodyChange} disabled={sending} required={true} />
                  </IonItem>

                  <IonItem>
                    <IonLabel>Sendgrid</IonLabel>
                    <IonCheckbox onIonChange={onSendgridChange} slot="end" value="Sendgrid" checked={sendgrid} />
                  </IonItem>

                  <IonItem>
                    <IonLabel>Twilio</IonLabel>
                    <IonCheckbox onIonChange={onTwilioChange} slot="end" value="Twilio" checked={twilio} />
                  </IonItem>

                  {sending && <FullPageSpinner />}

                  {!sending && (
                    <IonButton className="sendButton" type="submit" disabled={sending} expand="block">
                      Send
                    </IonButton>
                  )}
                </form>
              </IonContent>
            </IonCard>
          </div>
        ) : (
          <FullPageSpinner />
        )}
      </IonContent>
    </IonPage>
  );
};

export default AdminNotifications;

import React, { useContext, useEffect, useRef, useState } from 'react';

import { IonToast } from '@ionic/react';
import ReactGA from 'react-ga';
import { UserContext } from './UserContext';
import axios from 'axios';
import compareVersions from 'compare-versions';
import fire from '../firebase/base';
import getOS from '../hooks/getOS';
import netlifyURI from '../variables';

type PackageManagerContextProps = {
  checkForPackageUpdates: (displayToast: boolean) => void;
  launchProgram: Function;
  downloads: PackageObject[];
  availableBinaries: PackageObject[];
};

export interface PackageObject {
  file_name?: string;
  file_url?: string;
  platform?: string;
  product_id?: string;
  version?: string;
  downloadProgress?: number;
  product_title?: string;
}

export const PackageManagerContext = React.createContext<PackageManagerContextProps>({} as PackageManagerContextProps);

export const PackageManagerProvider = ({ children }: any) => {
  let ipcRenderer: any;
  const [toastMessage, setToastMessage] = React.useState('');
  const [showToast, setShowToast] = React.useState(false);
  const [downloads, setDownloads] = useState<PackageObject[]>([]);
  const [availableBinaries, setAvailableBinaries] = useState([] as PackageObject[]);
  const checkingForUpdates = useRef<boolean>();
  const { loggedInUser } = useContext(UserContext);

  if (window.require !== undefined && window.require !== null) {
    ipcRenderer = window.require('electron').ipcRenderer;
  }

  const os = getOS();

  useEffect(() => {
    if (loggedInUser && loggedInUser.id) {
      checkForPackageUpdates(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedInUser]);

  const syncSubscriptions = async () => {
    const firebaseAuth = fire.auth();
    if (!firebaseAuth.currentUser || !firebaseAuth.currentUser.uid) return false;
    try {
      await axios.post(netlifyURI + '.netlify/functions/syncSubscriptions', { userId: firebaseAuth.currentUser.uid });
      return true;
    } catch (err) {
      console.error('[Error 1.8923] ', err);
      return false;
    }
  };

  const launchProgram = (packageObj: PackageObject) => {
    ReactGA.event({
      category: 'Product Launch',
      action: 'User clicked Launch button'
    });

    const currentUser = fire.auth().currentUser;
    const refreshToken = currentUser ? currentUser.refreshToken : null;

    ipcRenderer.invoke('launchProgram', { executableFileName: packageObj.file_name, parameters: { refreshToken } }).then((success: boolean) => {
      if (!success) {
        setToastMessage('There was an error launching. Please restart and try again.');
        setShowToast(true);
      }
    });
  };

  const checkForPackageUpdates = async (displayToast: boolean) => {
    try {
      if (checkingForUpdates.current) {
        console.log('Checking for updates is already occurring. Exiting.');
        return;
      }
      checkingForUpdates.current = true;
      // console.log('Checking for updates', loggedInUser);
      console.log('Checking for updates');
      if (!ipcRenderer) {
        // must not be in electron - exit
        return;
        // throw new Error('Cannot download packages from web. Install launcher first.');
      }

      if (!loggedInUser || !loggedInUser.id) {
        throw new Error('No user logged in. Exiting update check.');
      }

      await syncSubscriptions();

      // TODO after next release we can set this!!!
      // const electronVersion: string = await ipcRenderer.invoke('getLauncherVersion');
      // Sentry.setUser({ id: loggedInUser?.id || '', launcher_version: electronVersion });

      setAvailableBinaries([]);

      if (!loggedInUser.subscriptions || !loggedInUser.subscriptions.length) {
        removeAllFilesExcept([]);
        throw new Error('You are not subscribed to any modules.');
      }

      const allowedFiles = [] as string[];

      const filesToCheckPromiseList = [];

      // for each of the subscriptions the user has
      for (const subscription of loggedInUser.subscriptions) {
        // if subscription is inactive move on
        if (!subscription.active) {
          // @TODO should this cancel subscription here instead of just skipping?
          console.log('Subscription no longer active: ', subscription);
          continue;
        }

        // get all packages with related stripe ID
        const stripePlanID = subscription.product;
        try {
          const packagesQueryResults = await fire
            .firestore()
            .collection('packages/' + stripePlanID + '/dist')
            .where('platform', '==', os)
            .get();
          if (packagesQueryResults.empty) {
            // no packages for this product
          } else {
            // get latest binary version
            let latestBinary: PackageObject = {};
            packagesQueryResults.forEach(doc => {
              if (!latestBinary.version) {
                latestBinary = doc.data();
                return;
              }

              if (compareVersions(latestBinary.version, doc.data().version) < 0) {
                console.log('Version ' + latestBinary.version + ' < ' + doc.data().version);
                latestBinary = doc.data();
              }
            });
            latestBinary.product_title = subscription.nickname;
            // console.log('latestBinary: ', latestBinary);
            allowedFiles.push(latestBinary.file_name!);
            const checkFileFunction = checkIfFileExists(latestBinary);
            filesToCheckPromiseList.push(checkFileFunction);
          }
        } catch (err) {
          console.error('[Error] - 1.334', err);

          if (displayToast) {
            setToastMessage('[Error] - 1.334' + err);
            setShowToast(true);
          }
        }
      }

      removeAllFilesExcept(allowedFiles);
      // removeAllFilesExcept([]);

      await Promise.all(filesToCheckPromiseList).then(function(values) {
        setAvailableBinaries(values.map(value => value.package));
        const isUpdated: boolean = !Boolean(values.find(value => !value.exists));

        if (isUpdated) {
          throw new Error('You are up to date.');
        } else {
          let packagesToDownload = values
            .filter(value => !value.exists)
            .map(value => {
              return value.package;
            });

          // this removes any packages with the same file name
          const hashTable: any = {};
          for (const item of packagesToDownload) {
            if (hashTable[item.file_name || 'null']) {
              packagesToDownload = packagesToDownload.filter(p => p !== item);
            } else {
              hashTable[item.file_name || 'null'] = true;
            }
          }

          const sortedDownloadList = sortDownloadsList(packagesToDownload);

          setDownloads(sortedDownloadList);
        }
      });
    } catch (err) {
      console.log(err.message);
      if (displayToast) {
        setToastMessage(err.message);
        setShowToast(true);
      }
    } finally {
      checkingForUpdates.current = false;
    }
  };

  const checkIfFileExists = async (latestBinary: PackageObject) => {
    const doesFileExist: boolean = await ipcRenderer.invoke('checkFileExists', latestBinary.file_name);
    return {
      exists: doesFileExist,
      package: latestBinary
    };
  };

  const sortDownloadsList = (packageList: PackageObject[]) => {
    return packageList.sort((a, b) => {
      if (!a.product_title || !b.product_title) return 0;
      if (a.product_title.toLowerCase() < b.product_title.toLowerCase()) {
        return -1;
      }
      if (a.product_title.toLowerCase() > b.product_title.toLowerCase()) {
        return 1;
      }
      return 0;
    });
  };

  useEffect(() => {
    const downloadProgressListener = (_event: any, { file_name, status }: any) => {
      const packageObj = downloads.find(item => item.file_name === file_name);
      if (!packageObj) {
        console.log('No correlated download object exists for:', file_name);
      } else {
        const cleanProgressInPercentages = Math.floor(status.percent * 100);
        if (packageObj.downloadProgress !== cleanProgressInPercentages && cleanProgressInPercentages > (packageObj.downloadProgress || 0)) {
          const filteredDownloadsList = downloads.filter(item => item.file_name !== file_name);
          const newDownloadList = [...filteredDownloadsList, { ...packageObj, downloadProgress: cleanProgressInPercentages }];
          const sortedDownloadList = sortDownloadsList(newDownloadList);
          setDownloads(sortedDownloadList);
        }
      }
    };

    const completeDownloadListener = (_event: any, file_name: string) => {
      const packageObj = downloads.find(item => item.file_name === file_name);
      if (!packageObj) {
        console.log('No correlated download object exists for:', file_name);
      } else {
        const filteredDownloadsList = downloads.filter(item => item.file_name !== file_name);
        const newDownloadList = [...filteredDownloadsList];
        const sortedDownloadList = sortDownloadsList(newDownloadList);
        console.log('Finished downloading', file_name);
        setDownloads(sortedDownloadList);
      }
    };

    const handleDownloadError = (_event: any, message: string) => {
      console.log(message);
      setToastMessage(message);
      setShowToast(true);
      const newDownloadList = [...downloads];
      newDownloadList.shift();
      const sortedDownloadList = sortDownloadsList(newDownloadList);
      setDownloads(sortedDownloadList);
    };

    if (ipcRenderer && ipcRenderer.on) {
      ipcRenderer.on('download progress', downloadProgressListener);
      ipcRenderer.on('download complete', completeDownloadListener);
      ipcRenderer.on('download error', handleDownloadError);
    }

    return () => {
      if (ipcRenderer && ipcRenderer.removeListener) {
        ipcRenderer.removeListener('download progress', downloadProgressListener);
        ipcRenderer.removeListener('download complete', completeDownloadListener);
        ipcRenderer.removeListener('download error', handleDownloadError);
      }
    };
  }, [downloads, ipcRenderer]);

  useEffect(() => {
    if (downloads && downloads.length > 0 && !downloads[0].downloadProgress) {
      console.log('Calling for Download', downloads[0].file_name);
      ipcRenderer.send('download', {
        file_name: downloads[0].file_name,
        url: downloads[0].file_url,
        properties: {}
      });
    }
  }, [downloads, ipcRenderer]);

  const removeAllFilesExcept = (filesToKeep: string[]) => {
    ipcRenderer.invoke('eraseFilesExceptFor', filesToKeep).then((err: any) => {
      if (err) {
        console.error(err);
      }
    });
  };

  return (
    <PackageManagerContext.Provider
      value={{
        checkForPackageUpdates,
        launchProgram,
        downloads,
        availableBinaries
      }}
    >
      <IonToast isOpen={showToast} position="middle" color="dark" onDidDismiss={() => setShowToast(false)} message={toastMessage} duration={3200} />
      {children}
    </PackageManagerContext.Provider>
  );
};

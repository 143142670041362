import './adminPackages.scss';

import { IonCard, IonCardHeader, IonContent, IonPage, IonTitle, IonToast, IonToolbar } from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';

import AdminPackagesTable from '../../components/admin-packages-table/AdminPackagesTable';
import FullPageSpinner from '../../components/full-page-spinner/fullPageSpinner';
import Header from '../../components/header/header';
import { Product } from '../../components/subscription-card/subcard';
import { UserContext } from '../../contexts/UserContext';
import fire from '../../firebase/base';
import useProducts from '../../hooks/useProducts';

const AdminPackages: React.FC = () => {
  const products = useProducts() as Product[];
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([] as any[]);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorToastState, setErrorToastState] = useState(false);

  const { loggedInUser } = useContext(UserContext);

  useEffect(() => {
    const updatePackages = () => {
      // eslint-disable-next-line array-callback-return
      products.map((product: any) => {
        const packagesTable = fire.firestore().collection('packages/' + product.id + '/dist');
        try {
          packagesTable.onSnapshot(snapshot => {
            snapshot.docChanges().forEach(change => {
              if (change.type === 'added') {
                setData(prevPackages => [...prevPackages, change.doc.data()]);
              }
            });
          });
        } catch (error) {
          setErrorMessage(error);
          setErrorToastState(true);
        }
      });
      setIsLoading(false);
    };
    updatePackages();
  }, [products]);

  const closeErrorToast = () => {
    setErrorToastState(false);
    setErrorMessage('');
  };

  return (
    <IonPage>
      <IonToast isOpen={errorToastState} onDidDismiss={closeErrorToast} message={errorMessage} cssClass="errorToastUploadModal" position="middle" duration={4000} />
      <Header />
      <IonContent className="screen-bg" fullscreen={true}>
        {loggedInUser && loggedInUser.admin ? (
          <div className="packageCardContainer">
            <IonCard className="packagesTableContainer">
              <IonCardHeader>
                <IonToolbar>
                  <IonTitle>Packages</IonTitle>
                </IonToolbar>
              </IonCardHeader>
              <AdminPackagesTable loading={isLoading} packages={data} />
            </IonCard>
          </div>
        ) : (
          <FullPageSpinner />
        )}
      </IonContent>
    </IonPage>
  );
};

export default AdminPackages;

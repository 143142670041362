import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';

import './loading.scss';

const Loading: React.FC = () => {
  return (
    <div className="full-container">
      <CircularProgress />
    </div>
  );
};

export default Loading;

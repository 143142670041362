import './subcard.scss';

import { IonAlert, IonButton, IonSpinner, IonToast } from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';

import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { stripe } from '../../App';
import { UserContext } from '../../contexts/UserContext';
import netlifyURI from '../../variables';

interface CardProps {
  product: Product;
  onIsLoadingChange: (isLoading: boolean) => void;
  disabled: boolean;
}

export interface ProductMetadata {
  cost: number;
  [key: string]: string | number;
}

export interface Product {
  id: string;
  active: boolean;
  name: string;
  metadata: ProductMetadata;
}

const SubCard: React.FC<CardProps> = ({ product, onIsLoadingChange, disabled }) => {
  const [errorToastState, setErrorToastState] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [displayAccessCodePromptQuestion, setDisplayAccessCodePromptQuestion] = useState(false);
  const [displayAccessCodePromptInput, setDisplayAccessCodePromptInput] = useState(false);

  const { loggedInUser } = useContext(UserContext);
  const { cost, plan_id } = product.metadata;

  const history = useHistory();

  // this crazy line just matches if this product is one of the ones the user is subscribed to and returns either false or the subscription
  const matchingUserSubscription = loggedInUser?.subscriptions
    ? loggedInUser.subscriptions.filter((item: any) => item.product === product.id).length
      ? loggedInUser.subscriptions.filter((item: any) => item.product === product.id)[0]
      : null
    : null;

  useEffect(() => {
    onIsLoadingChange(isLoading);
  }, [isLoading, onIsLoadingChange]);

  const displayAccessCodePrompt = () => {
    const isFreePlan = Number(cost) === 0;
    if (isFreePlan) {
      setupCheckout();
    } else {
      // setDisplayAccessCodePromptQuestion(true);
      setupCheckout();
    }
  };

  const setupCheckout = async (accessCodeObj?: { promotion_code: string; percent_off: number }) => {
    setIsLoading(true);
    if (!loggedInUser || !loggedInUser.email) {
      console.error('[Error 83.43] NO EMAIL');
      errorToastHandler('Sign in or create an account first.');
      history.push('/login');
      setIsLoading(false);
      return;
    }

    /**
     * This is here because there is actually a hardcoded limit on subscriptions
     * Firebase rules wont let you iterate through an array of subscriptions
     * so the rules literally have to check subscription[0], subscription[1] ... subscription[5]
     * */
    // if (loggedInUser.subscriptions && loggedInUser.subscriptions.length > 4) {
    //   errorToastHandler('You cannot have more than 5 subscriptions.');
    //   setIsLoading(false);
    //   return;
    // }

    const url =
      process.env.REACT_APP_LOCAL_LAMBDA_SERVE === 'true' ? 'http://localhost:9000/createStripeCheckoutSession' : netlifyURI + '.netlify/functions/createStripeCheckoutSession';

    const isFreePlan = Number(cost) === 0;

    try {
      const result = await axios.post(
        url +
          `?customerEmail=${encodeURIComponent(loggedInUser.email || '')}&stripeCustomerID=${loggedInUser.stripeCustomerID || ''}&plan_id=${plan_id}` +
          (isFreePlan ? '&planIsFree=true' : ''),
        accessCodeObj || {}
      );
      if (!result.data) {
        console.error('[Error 95.55] ', result);
        errorToastHandler('Error creating checkout session. Contact VxMed.');
        setIsLoading(false);
        return;
      }
      if (isFreePlan || (accessCodeObj && accessCodeObj.percent_off === 100)) {
        errorToastHandler(result.data.msg);
        if (result.data.success) {
          setIsLoading(false);
          return history.push('/home');
        }
      } else {
        stripe.redirectToCheckout({
          sessionId: result.data.session.id
        });
      }
      setIsLoading(false);
    } catch (err) {
      console.error('[Error 5.34] ', err);
      errorToastHandler('There was an unexpected error. Please contact VxMed.');
      setIsLoading(false);
    }
  };

  const cancelSubscription = async () => {
    setIsLoading(true);
    const url = process.env.REACT_APP_LOCAL_LAMBDA_SERVE === 'true' ? 'http://localhost:9000/cancelSubscription' : netlifyURI + '.netlify/functions/cancelSubscription';

    try {
      const result = await axios.post(url + `?stripeSubscriptionID=${matchingUserSubscription!.id}`);
      if (!result.data) {
        console.error('[Error 63.3] ', result);
        errorToastHandler('Error Canceling Subscription. Contact VxMed.');
        setIsLoading(false);
        return;
      }
      errorToastHandler('Subscription Canceled');
      setIsLoading(false);
    } catch (err) {
      console.error('[Error 1.5634] ', err);
      const result = await axios.post('https://app.vxmed.org/.netlify/functions/removeSubscriptionFBWebhook', { "data": { "object": { "id": `${matchingUserSubscription!.id}`, "customer":`${loggedInUser?.stripeCustomerID}` } } });
      if (!result.data) {
        errorToastHandler('There was an unexpected error. Please contact VxMed.');
        setIsLoading(false);
        return;
      }
      errorToastHandler('Subscription Canceled');
      setIsLoading(false);
    }
  };

  const getPerksArray = (metadata: any) => {
    const perksArray = [];
    for (const key in metadata) {
      if (metadata[key] && key.toLowerCase().indexOf('perk') !== -1) {
        perksArray.push(metadata[key]);
      }
    }
    return perksArray;
  };

  const perksArray = getPerksArray(product.metadata);

  const errorToastHandler = (errorMessage: any) => {
    setErrorMessage(errorMessage);
    setErrorToastState(true);
  };

  const closeErrorToast = () => {
    setErrorToastState(false);
  };

  const checkAccessCode = async (accessCode: string) => {
    setIsLoading(true);
    try {
      const url = process.env.REACT_APP_LOCAL_LAMBDA_SERVE === 'true' ? 'http://localhost:9000/checkDiscountCode' : netlifyURI + '.netlify/functions/checkDiscountCode';

      const result: any = await axios.post(url, { accessCode });

      if (!result || !result.data || !result.data.success) {
        setIsLoading(false);
        return errorToastHandler(result.data.msg || 'There was an error. Please try again or contact support');
      }

      const accessCodeObj = {
        promotion_code: result.data.accessCodeID,
        percent_off: result.data.accessCodePercentOff
      };

      setupCheckout(accessCodeObj);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      errorToastHandler('There was an error. Please refresh and try again or contact support');
    }
  };

  if (!product.active) {
    return null;
  }

  const generateCostString = (cost:any, name:string) => {
    let costString;
    //odd that function returns cost as a string
    if (cost === "0" || cost === 0) {
      costString = 'Free';
    } else {
      costString = '$ ' + cost + ' /';
      if (name === 'Continuing Medical Education (CME)') {
        costString += 'yr';
      } else {
        costString += 'mo';
      }
    }
    return costString;
  }

  return (
    <>
      <div className="card">
        <IonToast isOpen={errorToastState} cssClass="toastMessage" onDidDismiss={closeErrorToast} message={errorMessage} position="middle" duration={4000} />
        <h1 className="title">{product.name}</h1>
        <h1 className="cost">{generateCostString(cost, product.name)}</h1>
        <hr className="line" />
        <div className="perks">
          {perksArray.map((perk: string, idx: number) => (
            <React.Fragment key={idx}>
              <p>{perk}</p>
              <hr />
            </React.Fragment>
          ))}
        </div>
        {matchingUserSubscription ? (
          <IonButton disabled={isLoading || disabled} color="light" mode="ios" size="large" onClick={cancelSubscription}>
            {isLoading ? <IonSpinner name="crescent" /> : 'Cancel'}
          </IonButton>
        ) : (
          <IonButton disabled={isLoading || disabled} color="primary" mode="ios" size="large" onClick={displayAccessCodePrompt}>
            {isLoading ? <IonSpinner name="crescent" /> : 'Subscribe'}
          </IonButton>
        )}
      </div>
      <IonAlert
        mode="ios"
        buttons={[
          {
            text: 'No',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              setupCheckout();
            }
          },
          {
            text: 'Yes',
            handler: () => {
              setDisplayAccessCodePromptInput(true);
            }
          }
        ]}
        header={'Do you have an access code?'}
        isOpen={displayAccessCodePromptQuestion}
        onDidDismiss={() => setDisplayAccessCodePromptQuestion(false)}
      />
      <IonAlert
        mode="ios"
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              setDisplayAccessCodePromptInput(false);
            }
          },
          {
            text: 'Submit',
            handler: data => {
              checkAccessCode(data.accessCode);
            }
          }
        ]}
        inputs={[
          {
            name: 'accessCode',
            type: 'text',
            placeholder: 'Access Code'
          }
        ]}
        header={'Enter Access Code'}
        isOpen={displayAccessCodePromptInput}
        onDidDismiss={() => setDisplayAccessCodePromptInput(false)}
      />
    </>
  );
};

export default SubCard;

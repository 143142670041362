/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
/* Theme variables */
import './theme/variables.css';
/* Custom SCSS */
import './styles/styles.scss';

import { IonApp, IonRouterOutlet, IonSplitPane } from '@ionic/react';
import { Redirect, Route } from 'react-router-dom';

import Account from './pages/account/account';
import AdminHowTos from './pages/admin-howtos/admin-howtos';
import AdminNotifications from './pages/admin-notifications/AdminNotifications';
import AdminPackages from './pages/admin-packages/AdminPackages';
import AdminUsers from './pages/admin-users/AdminUsers';
import { IonReactRouter } from '@ionic/react-router';
import LaunchPage from './pages/launch-page/launch-page';
import Menu from './components/Menu/Menu';
import PrivateRoute from './firebase/privateRoute';
import React from 'react';
import Subscriptions from './pages/subscriptions/subscriptions';
import login from './pages/login/login';
import password from './pages/passwordReset/password';
import signup from './pages/signup/signup';

export const stripe = Stripe('pk_live_dCku9Fk9WuTo3Shm72omjIQy00y0qcHpkG');

const App: React.FC = () => {
  return (
    <IonApp>
      <IonReactRouter>
        <IonSplitPane contentId="menuContent">
          <Menu contentId="menuContent" />
          <IonRouterOutlet id="menuContent">
            <PrivateRoute path="/home" component={LaunchPage} exact={true} />
            <Route path="/subscriptions" component={Subscriptions} exact={true} />
            <Route path="/account" component={Account} exact={true} />
            <Route path="/passwordreset" component={password} exact={true} />
            <Route path="/signup" component={signup} exact={true} />
            <Route path="/login" component={login} exact={true} />
            <Route path="/admin/packages" component={AdminPackages} />
            <Route path="/admin/users" component={AdminUsers} />
            <Route path="/admin/notifications" component={AdminNotifications} />
            <Route exact={true} path="/admin/howtos" component={AdminHowTos} />
            <Redirect exact={true} from="/" to="/home" />
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;

import React, { useState, useEffect, useContext } from 'react';
import { IonPage, IonContent, IonToolbar, IonTitle, IonCard, IonToast, IonCardHeader } from '@ionic/react';
import AdminUserTable from '../../components/admin-user-table/AdminUserTable';
import fire from '../../firebase/base';
import './adminUsers.scss';
import Header from '../../components/header/header';
import { UserContext } from '../../contexts/UserContext';
import FullPageSpinner from '../../components/full-page-spinner/fullPageSpinner';

const AdminUsers: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([] as any[]);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorToastState, setErrorToastState] = useState(false);

  const { loggedInUser } = useContext(UserContext);

  useEffect(() => {
    const fetchUsersTable = () => {
      setErrorToastState(false);

      const usersTable = fire.firestore().collection('users');
      const usersArray: any = [];

      try {
        usersTable.get().then((users: any) => {
          users.forEach((doc: any) => {
            usersArray.push(doc.data());
          });
          setData(usersArray);
        });
      } catch (error) {
        setErrorMessage(error);
        setErrorToastState(true);
      }

      setIsLoading(false);
    };
    fetchUsersTable();
  }, []);

  const closeErrorToast = () => {
    setErrorToastState(false);
    setErrorMessage('');
  };

  return (
    <IonPage>
      <IonToast isOpen={errorToastState} onDidDismiss={closeErrorToast} message={errorMessage} cssClass="errorToastUploadModal" position="middle" duration={4000} />
      <Header />
      <IonContent className="screen-bg" fullscreen={true}>
        {loggedInUser && loggedInUser.admin ? (
          <div className="usersCardContainer">
            <IonCard className="usersTableContainer">
              <IonCardHeader>
                <IonToolbar>
                  <IonTitle>Users</IonTitle>
                </IonToolbar>
              </IonCardHeader>
              <AdminUserTable loading={isLoading} users={data} />
            </IonCard>
          </div>
        ) : (
          <FullPageSpinner />
        )}
      </IonContent>
    </IonPage>
  );
};

export default AdminUsers;

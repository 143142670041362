import { useState, useEffect } from 'react';
import netlifyURI from '../variables';
import axios from 'axios';

const useProducts = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const url = process.env.REACT_APP_LOCAL_LAMBDA_SERVE === 'true' ? 'http://localhost:9000/getProducts.js' : netlifyURI + '.netlify/functions/getProducts';
      try {
        // eslint-disable-next-line no-unused-vars
        const result = await axios({
          url: url,
          method: 'GET'
        }).then(response => {
          setProducts(response.data.data);
        });
      } catch (err) {
        console.error('[Error 9824.2] ', err);
      }
    };
    fetchData();
  }, []);

  return products;
};

export default useProducts;

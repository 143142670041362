import React, { useState } from 'react';
import fire from '../../firebase/base';
import { withRouter, Redirect } from 'react-router';
import {
  IonText,
  IonCard,
  IonCardContent,
  IonPage,
  IonCardHeader,
  IonCardTitle,
  IonContent,
  IonInput,
  IonItem,
  IonLabel,
  IonButton,
  IonCardSubtitle,
  IonRouterLink
} from '@ionic/react';
import './password.scss';
import Footer from '../../components/footer/footer';
import Header from '../../components/header/header';

const Password = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setsuccessMessage] = useState('');

  const handlePassword = (event: any) => {
    event.preventDefault();
    const { email } = event.target.elements;
    fire
      .auth()
      .sendPasswordResetEmail(email.value)
      .then(function() {
        setsuccessMessage('Password reset link was sent to your email.');
      })
      .catch(function(error) {
        setErrorMessage(error.message);
      });
  };

  if (fire.auth().currentUser) {
    return <Redirect to="/home" />;
  }

  return (
    <IonPage>
      <Header />
      <IonContent className="screen-bg">
        <div className="resetContainer">
          <IonCard className="reset-form">
            <IonCardHeader>
              <IonCardTitle className="ion-text-center">Password Reset</IonCardTitle>
              <br />
              <IonCardSubtitle color="danger">{errorMessage}</IonCardSubtitle>
              <IonCardSubtitle color="primary">{successMessage}</IonCardSubtitle>
            </IonCardHeader>

            <IonCardContent>
              <form onSubmit={handlePassword}>
                <IonItem className="resetItem">
                  <IonLabel position="floating">Email</IonLabel>
                  <IonInput name="email" type="email" />
                </IonItem>

                <IonButton color="primary" className="reset-button" expand="block" type="submit">
                  Reset
                </IonButton>

                <IonText className="ion-text-center">
                  Not a user? <IonRouterLink routerLink="/signup">Sign up here!</IonRouterLink>
                </IonText>
                <br />
                <br />
                <IonText className="ion-text-center">
                  Already a user? <IonRouterLink routerLink="/login">Login here!</IonRouterLink>
                </IonText>
              </form>
            </IonCardContent>
          </IonCard>
        </div>
      </IonContent>
      <Footer />
    </IonPage>
  );
};

export default withRouter(Password);

import React, { useState } from 'react';
import fire from '../../firebase/base';
import { withRouter, Redirect } from 'react-router';
import {
  IonText,
  IonCard,
  IonCardContent,
  IonPage,
  IonCardHeader,
  IonCardTitle,
  IonContent,
  IonInput,
  IonItem,
  IonLabel,
  IonButton,
  IonSelect,
  IonSelectOption,
  IonCheckbox,
  IonToast,
  IonRouterLink
} from '@ionic/react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import './signup.scss';
import Footer from '../../components/footer/footer';
import Header from '../../components/header/header';

const Signup = ({ history }: any) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [errorToastState, setErrorToastState] = useState(false);
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      password2: '',
      schoolAffiliation: '',
      education: '',
      collaborator: false
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .max(15, 'Must be 15 characters or less')
        .required('Required'),
      lastName: Yup.string()
        .max(20, 'Must be 20 characters or less')
        .required('Required'),
      email: Yup.string()
        .email('Invalid email address')
        .required('Required'),
      password: Yup.string().required('Required'),
      password2: Yup.string().required('Required'),
      schoolAffiliation: Yup.string()
        .max(50, 'Must be 50 characters or less')
        .required('Required'),
      education: Yup.string()
        .max(50, 'Must be 50 characters or less')
        .required('Required'),
      collaborator: Yup.boolean(),
      subscription: Yup.string()
    }),
    onSubmit: (_, formikHelpers) => {
      formikHelpers.setSubmitting(true);
      handleSignUp();
    }
  });

  const db = fire.firestore();

  function returnUserId(email: string, password: string) {
    return new Promise((resolve, reject) => {
      fire
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then((res: any) => {
          if (res.user) {
            resolve(res.user.uid);
          }
        })
        .catch(error => {
          formik.setSubmitting(false);
          reject(error);
          errorToastHandler(error.message);
          if (error.message === 'The email address is already in use by another account.') {
            formik.setFieldError('email', error.message);
          }
        });
    });
  }

  const handleSignUp = async () => {
    if (formik.values.password2 === formik.values.password) {
      const userID: any = await returnUserId(formik.values.email, formik.values.password);
      createUserDb(userID, {
        firstName: formik.values.firstName,
        lastName: formik.values.lastName,
        email: formik.values.email,
        schoolAffiliation: formik.values.schoolAffiliation,
        education: formik.values.education,
        collaborator: formik.values.collaborator
      });
      history.push('/home');
    } else {
      formik.setSubmitting(false);
      formik.setFieldError('password', 'Passwords must match');
      formik.setFieldError('password2', 'Passwords must match');
      errorToastHandler('Passwords must match');
    }
  };

  const createUserDb = async (id: string, userData: any) => {
    await db
      .collection('users')
      .doc(id)
      .set(userData);
  };

  const setWillCollaborate = () => {
    formik.setFieldValue('collaborator', !formik.values.collaborator);
  };

  const edSelection = (event: any) => {
    formik.setFieldValue('education', event.target.value);
  };

  const closeErrorToast = () => {
    setErrorToastState(false);
    setErrorMessage('');
  };

  const errorToastHandler = (errorMessage: string) => {
    setErrorMessage(errorMessage);
    setErrorToastState(true);
  };

  if (fire.auth().currentUser) {
    return <Redirect to="/home" />;
  }

  return (
    <IonPage>
      <IonToast isOpen={errorToastState} onDidDismiss={closeErrorToast} message={errorMessage} cssClass="errorToast" position="top" duration={4000} />
      <Header />
      <IonContent className="screen-bg" fullscreen={true}>
        <div className="signupFormContainer">
          <IonCard className="signup-form ">
            <IonCardHeader>
              <IonCardTitle className="ion-text-center">Sign Up</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
              <form onSubmit={formik.handleSubmit}>
                <IonItem className="signupItem">
                  <IonLabel position="floating">First Name</IonLabel>
                  <IonInput id="firstName" name="firstName" type="text" onInput={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.firstName} />
                </IonItem>
                {formik.touched.firstName && formik.errors.firstName ? <p className="signup-error-text">{formik.errors.firstName}</p> : null}
                <IonItem className="signupItem">
                  <IonLabel position="floating">Last Name</IonLabel>
                  <IonInput id="lastName" name="lastName" type="text" onInput={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.lastName} />
                </IonItem>
                {formik.touched.lastName && formik.errors.lastName ? <p className="signup-error-text">{formik.errors.lastName}</p> : null}
                <IonItem className="signupItem">
                  <IonLabel position="floating">Email</IonLabel>
                  <IonInput id="email" name="email" type="email" onInput={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.email} />
                </IonItem>
                {formik.touched.email && formik.errors.email ? <p className="signup-error-text">{formik.errors.email}</p> : null}
                <IonItem className="signupItem">
                  <IonLabel position="floating">Password</IonLabel>
                  <IonInput id="password" name="password" type="password" onInput={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.password} />
                </IonItem>
                {formik.touched.password && formik.errors.password ? <p className="signup-error-text">{formik.errors.password}</p> : null}
                <IonItem className="signupItem">
                  <IonLabel position="floating"> Verify Password</IonLabel>
                  <IonInput id="password2" name="password2" type="password" onInput={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.password2} />
                </IonItem>
                {formik.touched.password2 && formik.errors.password2 ? <p className="signup-error-text">{formik.errors.password2}</p> : null}
                <IonItem className="signupItem">
                  <IonLabel position="floating">School Affiliation</IonLabel>
                  <IonInput
                    id="schoolAffiliation"
                    name="schoolAffiliation"
                    type="text"
                    onInput={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.schoolAffiliation}
                  />
                </IonItem>
                {formik.touched.schoolAffiliation && formik.errors.schoolAffiliation ? <p className="signup-error-text">{formik.errors.schoolAffiliation}</p> : null}
                <IonItem>
                  <IonLabel>Education</IonLabel>
                  <IonSelect value={formik.values.education} okText="Okay" cancelText="Dismiss" onIonChange={edSelection}>
                    <IonSelectOption value="First Year Medical Student">First Year Medical Student</IonSelectOption>
                    <IonSelectOption value="Second Year Medical Student">Second Year Medical Student</IonSelectOption>
                    <IonSelectOption value="Third Year Medical Student">Third Year Medical Student</IonSelectOption>
                    <IonSelectOption value="Fourth Year Medical Student">Fourth Year Medical Student</IonSelectOption>
                    <IonSelectOption value="Resident (MD/DO)">Resident (MD/DO)</IonSelectOption>
                    <IonSelectOption value="Fellow (MD/DO)">Fellow (MD/DO)</IonSelectOption>
                    <IonSelectOption value="Attending Physician (MD/DO)">Attending Physician (MD/DO)</IonSelectOption>
                    <IonSelectOption value="Other Medical Professional">Other Medical Professional</IonSelectOption>
                    <IonSelectOption value="Other(Non Medical Professional)">Other (Non Medical Professional</IonSelectOption>
                  </IonSelect>
                </IonItem>
                {formik.touched.education && formik.errors.education ? <p className="signup-error-text">{formik.errors.education}</p> : null}
                <IonItem>
                  <IonLabel>Check if you would like to help build VxMED content</IonLabel>
                  <IonCheckbox slot="end" onClick={setWillCollaborate} />
                </IonItem>

                <IonButton className="signup-button" expand="block" type="submit" disabled={formik.isSubmitting}>
                  Sign Up
                </IonButton>

                <IonText className="ion-text-center">
                  Already a user? <IonRouterLink routerLink="/login">Login here!</IonRouterLink>
                </IonText>
                <br />
                <br />
                <IonText className="ion-text-center">
                  <IonRouterLink routerLink="/subscriptions">Check out our Subscriptions </IonRouterLink>
                </IonText>
              </form>
            </IonCardContent>
          </IonCard>
        </div>
      </IonContent>
      <Footer />
    </IonPage>
  );
};

export default withRouter(Signup);

import React from 'react';
import { IonSpinner } from '@ionic/react';
import './fullPageSpinner.scss';

const FullPageSpinner = () => {
  return (
    <div className="ionSpinnerContainer">
      <IonSpinner />
    </div>
  );
};

export default FullPageSpinner;

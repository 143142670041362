import { IonButton, IonSelect, IonSelectOption } from '@ionic/react';
import React, { useState } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

import AdminAddPackageModal from '../admin-add-package-modal/AdminAddPackageModal';
import Loading from '../loading/loading';
import Paper from '@material-ui/core/Paper';
import { Product } from '../subscription-card/subcard';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import useProducts from '../../hooks/useProducts';

interface Data {
  version: number;
  file_name: string;
  platform: string;
  product_id: string;
}

function desc<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array: any[], cmp: (a: any, b: any) => number) {
  if (!array) return [];
  const stabilizedThis = array.map((el, index) => [el, index]) as any[];
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]) as any[];
}

type Order = 'asc' | 'desc';

function getSorting<K extends keyof any>(order: Order, orderBy: K): (a: { [key in K]: number | string }, b: { [key in K]: number | string }) => number {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
}

const headCells: HeadCell[] = [
  { id: 'product_id', disablePadding: true, label: 'Product ID' },
  { id: 'file_name', disablePadding: false, label: 'File Name' },
  { id: 'platform', disablePadding: false, label: 'Platform' },
  {
    id: 'version',
    disablePadding: false,
    label: 'Version'
  }
];

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell key={headCell.id} align="left" sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : 'asc'} onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? <span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span> : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%'
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2)
    },
    table: {
      minWidth: 675
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1
    }
  })
);

type AdminUserTableProps = { loading: boolean; packages: any[] };

const AdminPackagesTable: React.FC<AdminUserTableProps> = ({ loading, packages }) => {
  const products = useProducts() as Product[];
  const classes = useStyles();
  const [filter, setFilter] = useState(null);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof Data>('product_id');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleRequestSort = (_event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filterSelection = (event: any) => {
    setFilter(event.target.value);
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, packages.length - page * rowsPerPage);

  const filterItems = (e: any) => {
    filterSelection(e);
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <IonSelect onIonChange={filterItems} placeholder="Select Product">
            {products.map((x: any, i) => {
              return (
                <IonSelectOption key={i} value={x.id}>
                  {x.name}
                </IonSelectOption>
              );
            })}
          </IonSelect>
          <Table className={classes.table} aria-labelledby="tableTitle" size="medium" aria-label="enhanced table">
            <EnhancedTableHead classes={classes} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={packages.length} />
            <TableBody>
              {stableSort(packages, getSorting(order, orderBy))
                .filter(p => p.product_id === filter)
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((p, index) => {
                  const labelId = `${index}`;

                  return (
                    <TableRow hover={true} tabIndex={-1} key={index}>
                      <TableCell component="th" id={labelId} scope="p">
                        {p.product_id}
                      </TableCell>
                      <TableCell>{p.file_name}</TableCell>
                      <TableCell>{p.platform}</TableCell>
                      <TableCell>{p.version}</TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={packages.filter(p => p.product_id === filter).length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      <IonButton onClick={openModal} className="packageBTN">
        Add package
      </IonButton>
      <AdminAddPackageModal open={showModal} closeModal={closeModal} />
    </div>
  );
};
export default AdminPackagesTable;

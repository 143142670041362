import React, { useState } from 'react';
import { IonAlert } from '@ionic/react';

type PopConfirmContextProps = {
  openAlert: Function;
  setConfirmHandler: Function;
  confirmHandler?: Function | null;
};

type PopConfirmProviderProps = { children: React.ReactNode };

export const PopConfirmContext = React.createContext<PopConfirmContextProps>({} as PopConfirmContextProps);

export const PopConfirmProvider = ({ children }: PopConfirmProviderProps) => {
  const [open, setOpen] = useState(false);
  const [confirmHandler, setConfirmHandler] = useState<Function | null>(null);

  const openAlert = () => {
    setOpen(true);
  };

  const onConfirm = (confirmFunc: Function) => {
    confirmFunc();
  };

  const dismissAlert = () => {
    setOpen(false);
  };

  return (
    <PopConfirmContext.Provider value={{ openAlert, setConfirmHandler, confirmHandler }}>
      <IonAlert
        isOpen={open}
        onDidDismiss={dismissAlert}
        header={'Warning'}
        message={'Are you sure you want to logout?'}
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => dismissAlert()
          },
          {
            text: 'Logout',
            handler: () => {
              if (!confirmHandler) return;
              onConfirm(confirmHandler);
              setConfirmHandler(null);
            }
          }
        ]}
      />
      {children}
    </PopConfirmContext.Provider>
  );
};

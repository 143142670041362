import './launch-page.scss';

import { IonButton, IonContent, IonPage, IonToast } from '@ionic/react';
import React, { useEffect, useState } from 'react';

import DownloadList from '../../components/download-list/DownloadList';
import Footer from '../../components/footer/footer';
import Header from '../../components/header/header';
import LaunchButton from '../../components/launch-button/LaunchButton';
import getOS from '../../hooks/getOS';

const LaunchPage: React.FC = () => {
  const [downloadURL, setDownloadURL] = useState('');
  const [selectedPackageIndex, setSelectedPackageIndex] = useState(0);
  const [toastMessage, setToastMessage] = useState('');
  const [showToast, setShowToast] = useState(false);

  const displayToast = (message: string) => {
    setToastMessage(message);
    setShowToast(true);
  };

  useEffect(() => {
    const os = getOS();

    const settingDownloadURL = () => {
      if (os === 'Mac') {
        setDownloadURL('https://vxmed.s3.us-east-2.amazonaws.com/installers/VxMed.dmg');
      } else if (os === 'Windows') {
        setDownloadURL('https://vxmed.s3.us-east-2.amazonaws.com/installers/VxMed.exe');
      }
    };
    settingDownloadURL();
  }, []);

  if (window.require === undefined) {
    return (
      <IonPage>
        <Header />
        <IonContent className="screen-bg" fullscreen={true}>
          <div className="launchPageBody">
            <IonButton className="download-launcher-button" color="primary" mode="ios" size="large" href={downloadURL} target="_blank" rel="noopener">
              Download Launcher
            </IonButton>
          </div>
        </IonContent>
        <Footer />
      </IonPage>
    );
  } else
    return (
      <IonPage>
        <Header />
        <IonContent className="screen-bg" fullscreen={true}>
          <div className="launchPageBody">
            {/* tslint:disable-next-line: jsx-no-lambda */}
            <IonToast isOpen={showToast} position="middle" color="dark" onDidDismiss={() => setShowToast(false)} message={toastMessage} duration={3200} />

            <LaunchButton setSelectedPackageIndex={setSelectedPackageIndex} selectedPackageIndex={selectedPackageIndex} displayToast={displayToast} />
            <DownloadList selectedPackageIndex={selectedPackageIndex} />
          </div>
        </IonContent>
        <Footer />
      </IonPage>
    );
};

export default LaunchPage;

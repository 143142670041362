import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import fire from './base';

const PrivateRoute = ({ component: RouteComponent, ...rest }: any) => {
  const renderCompOrRedirect = (routeProps: any) => {
    if (fire.auth().currentUser) {
      return <RouteComponent {...routeProps} />;
    } else {
      return <Redirect to={'/login'} />;
    }
  };

  return <Route {...rest} render={renderCompOrRedirect} />;
};

export default PrivateRoute;

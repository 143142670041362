import React from 'react';
import './footer.scss';
import { IonFooter } from '@ionic/react';

const Footer: React.FC = () => {
  const npmVersion = require('../../../package.json').version;

  return (
    <IonFooter mode="ios" className="ion-footer-z-fix">
      <div className="footer">
        <p>{npmVersion}</p>
      </div>
    </IonFooter>
  );
};

export default Footer;

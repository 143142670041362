import React, { useContext } from 'react';
import { IonPage, IonContent } from '@ionic/react';
import './admin-howtos.scss';
import Header from '../../components/header/header';
import { UserContext } from '../../contexts/UserContext';
import FullPageSpinner from '../../components/full-page-spinner/fullPageSpinner';

const AdminHowTos: React.FC = () => {
  const { loggedInUser } = useContext(UserContext);
  const videoUrls = [
    {
      title: 'Add Product/Subscription',
      url: 'https://res.cloudinary.com/nextgen-code-company/video/upload/v1579548859/VxMed%20How-To%20Videos/Subscription%20in%20stripe.mov'
    },
    {
      title: 'Modify Product/Subscription',
      url: 'https://res.cloudinary.com/nextgen-code-company/video/upload/v1579549169/VxMed%20How-To%20Videos/Modify_a_product_or_subscription_in_stripe_cvifpr.mov'
    },
    {
      title: 'Add a Binary',
      url: 'https://res.cloudinary.com/nextgen-code-company/video/upload/v1579549981/VxMed%20How-To%20Videos/Add_a_binary_clabrk.mov'
    },
    {
      title: 'Modify/Cancel Subscription',
      url: 'https://res.cloudinary.com/nextgen-code-company/video/upload/v1579550594/VxMed%20How-To%20Videos/Update_or_cancel_subscription_huts7t.mov'
    },
    {
      title: 'Firebase: Delete a user',
      url: 'https://res.cloudinary.com/nextgen-code-company/video/upload/v1581961407/VxMed%20How-To%20Videos/FB_delete_a_user_i1zaam.mov'
    },
    {
      title: 'Firebase: Edit user data',
      url: 'https://res.cloudinary.com/nextgen-code-company/video/upload/v1580935435/VxMed%20How-To%20Videos/FB_edit_user_data_nk1zod.mov'
    },
    {
      title: 'Firebase: Make user admin',
      url: 'https://res.cloudinary.com/nextgen-code-company/video/upload/v1580935432/VxMed%20How-To%20Videos/FB_make_user_admin_nwf3ip.mov'
    }
  ];

  return (
    <IonPage>
      <Header />
      <IonContent className="screen-bg" fullscreen={true}>
        {loggedInUser && loggedInUser.admin ? (
          videoUrls.map((item, index) => (
            <div className="video-list-item" key={index}>
              <h2>{item.title}</h2>
              <video src={item.url} controls={true} />
            </div>
          ))
        ) : (
          <FullPageSpinner />
        )}
      </IonContent>
    </IonPage>
  );
};

export default AdminHowTos;

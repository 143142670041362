import React, { useState, useContext } from 'react';
import fire from '../../firebase/base';
import { withRouter, Redirect } from 'react-router';
import { IonToast, IonText, IonCard, IonCardContent, IonPage, IonCardHeader, IonCardTitle, IonContent, IonInput, IonItem, IonLabel, IonButton, IonRouterLink } from '@ionic/react';
import './login.scss';
import Footer from '../../components/footer/footer';
import Header from '../../components/header/header';
import { UserContext } from '../../contexts/UserContext';

const Login = ({ history }: any) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorToastState, setErrorToastState] = useState(false);

  const { setLoggedInUser } = useContext(UserContext);

  const usersTable = fire.firestore().collection('users');

  const handleLogin = (event: any) => {
    event.preventDefault();
    fire
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((res: any) => {
        usersTable
          .doc(res.user.uid)
          .get()
          .then(doc => {
            if (setLoggedInUser) setLoggedInUser(doc.data());
          });
      })
      .catch(function(error) {
        if (!error) {
          history.push('/home');
        } else {
          setErrorMessage(error.message);
          setErrorToastState(true);
        }
      });
  };

  const closeErrorToast = () => {
    setErrorToastState(false);
    setErrorMessage('');
  };

  const onKeyDown = (event: any): void => {
    // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      handleLogin(event);
    }
  };

  const setFormValue = (e: any) => {
    if (e.target.name === 'email') {
      setEmail(e.detail.value);
    } else if (e.target.name === 'password') {
      setPassword(e.detail.value);
    }
  };

  if (fire.auth().currentUser) {
    return <Redirect to="/home" />;
  }

  return (
    <IonPage>
      <IonToast isOpen={errorToastState} onDidDismiss={closeErrorToast} message={errorMessage} cssClass="errorToastLogin" position="middle" duration={4000} />
      <Header />
      <IonContent className="screen-bg" fullscreen={true}>
        <div className="loginContainer">
          <IonCard className="login-form">
            <IonCardHeader>
              <IonCardTitle className="ion-text-center">Login</IonCardTitle>
              <br />
            </IonCardHeader>

            <IonCardContent>
              <form onSubmit={handleLogin}>
                <IonItem className="loginItem">
                  <IonLabel position="floating">Email</IonLabel>
                  <IonInput onKeyUp={onKeyDown} name="email" type="email" onIonChange={setFormValue} value={email} />
                </IonItem>

                <IonItem className="loginItem">
                  <IonLabel position="floating">Password</IonLabel>
                  <IonInput onKeyUp={onKeyDown} name="password" type="password" onIonChange={setFormValue} value={password} />
                </IonItem>

                <IonButton className="login-button" type="submit" expand="block">
                  Login
                </IonButton>

                <IonText className="ion-text-center">
                  Not a user? <IonRouterLink routerLink="/signup">Sign up here!</IonRouterLink>
                </IonText>
                <br />
                <br />
                <IonText className="ion-text-center">
                  <IonRouterLink routerLink="/passwordreset">Forgot password</IonRouterLink>
                </IonText>
                <br />
                <br />
                <IonText className="ion-text-center">
                  <IonRouterLink routerLink="/subscriptions">Check out our Subscriptions </IonRouterLink>
                </IonText>
              </form>
            </IonCardContent>
          </IonCard>
        </div>
      </IonContent>
      <Footer />
    </IonPage>
  );
};

export default withRouter(Login);

import { Menu, MenuItem } from '@material-ui/core';
import { PackageManagerContext, PackageObject } from '../../contexts/PackageManagerContext';
import React, { useContext } from 'react';

import { IonIcon } from '@ionic/react';
import { arrowDropdown } from 'ionicons/icons';
import { useHistory } from 'react-router-dom';

type LaunchButtonProps = { selectedPackageIndex: number; setSelectedPackageIndex: Function; displayToast: Function };

const LaunchButton: React.FC<LaunchButtonProps> = ({ selectedPackageIndex, setSelectedPackageIndex, displayToast }) => {
  const { launchProgram, checkForPackageUpdates, availableBinaries, downloads } = useContext(PackageManagerContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const history = useHistory();
  const isDownloadingPackage = downloads && downloads.length > 0 && downloads.find(item => item.file_name === availableBinaries[selectedPackageIndex].file_name);

  const handleClick = (event: any) => {
    event.persist();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const selectPackage = (index: any) => {
    setSelectedPackageIndex(index);
    setAnchorEl(null);
  };

  const launchPackage = () => {
    if (!availableBinaries || !availableBinaries.length) {
      checkForPackageUpdates(true);
      history.push('/subscriptions');
    } else if (isDownloadingPackage) {
      displayToast('Please wait until the download is finished.');
    } else {
      launchProgram(availableBinaries[selectedPackageIndex]);
    }
  };

  return (
    <>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {availableBinaries!.map((packageObj: PackageObject, index: number) => (
          <MenuItem key={index} onClick={() => selectPackage(index)}>
            {packageObj.product_title}
          </MenuItem>
        ))}
      </Menu>
      <div className="launch-button">
        <div className={'launch-button-text' + (isDownloadingPackage ? ' disabled' : '')} onClick={launchPackage}>
          <div>{isDownloadingPackage ? 'Downloading' : 'Launch'}</div>
        </div>
        {availableBinaries && availableBinaries.length ? (
          <div className="launch-button-selected" onClick={handleClick}>
            <div>{(availableBinaries[selectedPackageIndex] && availableBinaries[selectedPackageIndex].product_title) || 'Unknown'}</div>
            <div>
              <IonIcon icon={arrowDropdown} mode="md" />
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default LaunchButton;

import './account.scss';
import 'firebase/auth';

import * as firebase from 'firebase/app';

import { IonButton, IonCard, IonCardHeader, IonContent, IonInput, IonItem, IonLabel, IonPage, IonSelect, IonSelectOption, IonTitle, IonToast, IonToolbar } from '@ionic/react';
import React, { useContext, useState } from 'react';
import { Redirect, withRouter } from 'react-router';

import Footer from '../../components/footer/footer';
import Header from '../../components/header/header';
import { UserContext } from '../../contexts/UserContext';
import fire from '../../firebase/base';

const Account = () => {
  const user = fire.auth().currentUser!;

  const [toastState, setToastState] = useState(false);
  const [toastSuccess, setToastSuccess] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [edit, setEdit] = useState(false);
  const [educationChange, setEducationChange] = useState('');
  const { loggedInUser } = useContext(UserContext);

  const changePassword = (event: any) => {
    event.preventDefault();
    const { oldPassword, newPassword, newPassword2 } = event.target.elements;
    const credential = firebase.auth.EmailAuthProvider.credential(user.email!, oldPassword.value);

    if (newPassword.value === newPassword2.value) {
      user!
        .reauthenticateWithCredential(credential)
        .then(() => {
          user.updatePassword(newPassword.value).then(() => {
            setToastSuccess(true);
            activateToast('Password change successful!');
            oldPassword.value = '';
            newPassword.value = '';
            newPassword2.value = '';
          });
        })
        .catch(error => {
          setToastSuccess(false);
          activateToast(error.message);
        });
    } else {
      setToastSuccess(false);
      activateToast('Your password confirmation did not match your password!');
    }
  };

  const closeToast = () => {
    setToastState(false);
    setToastMessage('');
  };

  const activateToast = (message: string) => {
    setToastMessage(message);
    setToastState(true);
  };

  const profileEdit = async (event: any) => {
    event.preventDefault();
    if (!loggedInUser || !loggedInUser.id) {
      throw new Error('No user account to update.');
    }

    if (!edit) {
      setEdit(true);
    } else if (edit) {
      const { firstName, lastName, schoolAffiliation } = event.target.elements;
      const newData = {
        firstName: firstName.value ? firstName.value : loggedInUser.firstName,
        lastName: lastName.value ? lastName.value : loggedInUser.lastName,
        schoolAffiliation: schoolAffiliation.value ? schoolAffiliation.value : loggedInUser.schoolAffiliation,
        education: educationChange
      };
      await fire
        .firestore()
        .collection('users')
        .doc(loggedInUser.id)
        .update(newData)
        .then(() => {
          setEdit(false);
        });
    }
  };

  const newEducation = (event: any) => {
    setEducationChange(event.target.value);
  };

  const beginEdit = () => setEdit(true);

  if (!user) {
    return <Redirect to="/home" />;
  }

  return (
    <IonPage>
      <Header />
      <IonContent className="screen-bg" fullscreen={true}>
        <div className="settings-form-container">
          <IonToast
            isOpen={toastState}
            onDidDismiss={closeToast}
            message={toastMessage}
            color={toastSuccess ? 'success' : ''}
            cssClass="messageToast"
            position="middle"
            duration={4000}
          />
          <IonCard className="settings-form">
            <IonCardHeader>
              <IonToolbar>
                <IonTitle>User Profile</IonTitle>
              </IonToolbar>
            </IonCardHeader>

            <div className="userFormBodyContainer">
              {edit ? (
                <form onSubmit={profileEdit}>
                  <div className="userInfoContainer">
                    <IonItem className="settingsItem">
                      <IonLabel position="floating">First Name</IonLabel>
                      <IonInput name="firstName" type="text" placeholder={loggedInUser?.firstName} />
                    </IonItem>
                    <IonItem className="settingsItem">
                      <IonLabel position="floating">Last Name</IonLabel>
                      <IonInput name="lastName" type="text" placeholder={loggedInUser?.lastName} />
                    </IonItem>
                    <IonItem className="settingsItem">
                      <IonLabel position="floating">School Affiliation</IonLabel>
                      <IonInput name="schoolAffiliation" type="text" placeholder={loggedInUser?.schoolAffiliation} />
                    </IonItem>
                    <IonItem>
                      <IonLabel>Education</IonLabel>
                      <IonSelect value={educationChange} okText="Okay" cancelText="Dismiss" onIonChange={newEducation}>
                        <IonSelectOption value="First Year Medical Student">First Year Medical Student</IonSelectOption>
                        <IonSelectOption value="Second Year Medical Student">Second Year Medical Student</IonSelectOption>
                        <IonSelectOption value="Third Year Medical Student">Third Year Medical Student</IonSelectOption>
                        <IonSelectOption value="Fourth Year Medical Student">Fourth Year Medical Student</IonSelectOption>
                        <IonSelectOption value="Resident (MD/DO)">Resident (MD/DO)</IonSelectOption>
                        <IonSelectOption value="Fellow (MD/DO)">Fellow (MD/DO)</IonSelectOption>
                        <IonSelectOption value="Attending Physician (MD/DO)">Attending Physician (MD/DO)</IonSelectOption>
                        <IonSelectOption value="Other Medical Professional">Other Medical Professional</IonSelectOption>
                        <IonSelectOption value="Other(Non Medical Professional)">Other (Non Medical Professional</IonSelectOption>
                      </IonSelect>
                    </IonItem>
                    <IonButton fill="solid" expand="block" type="submit">
                      Save
                    </IonButton>
                  </div>
                </form>
              ) : (
                <div className="userInfoContainer">
                  <IonItem className="settingsItem">
                    <IonLabel position="floating">First Name</IonLabel>
                    <IonInput value={loggedInUser?.firstName} name="firstName" type="text" disabled={true} />
                  </IonItem>
                  <IonItem className="settingsItem">
                    <IonLabel position="floating">Last Name</IonLabel>
                    <IonInput value={loggedInUser?.lastName} name="lastName" type="text" disabled={true} />
                  </IonItem>
                  <IonItem className="settingsItem">
                    <IonLabel position="floating">Email</IonLabel>
                    <IonInput value={loggedInUser?.email} name="email" type="email" disabled={true} />
                  </IonItem>
                  <IonItem className="settingsItem">
                    <IonLabel position="floating">School Affiliation</IonLabel>
                    <IonInput value={loggedInUser?.schoolAffiliation} name="school_affil" type="text" disabled={true} />
                  </IonItem>
                  <IonItem className="settingsItem">
                    <IonLabel position="floating">Education</IonLabel>
                    <IonInput value={loggedInUser?.education} name="education" type="text" disabled={true} />
                  </IonItem>
                  <IonButton fill="clear" expand="block" onClick={beginEdit}>
                    Edit Profile
                  </IonButton>
                </div>
              )}
              <form onSubmit={changePassword}>
                <div className="passwordResetContainer">
                  <IonCardHeader>
                    <IonToolbar>
                      <IonTitle>Change Password</IonTitle>
                    </IonToolbar>
                  </IonCardHeader>
                  <IonItem className="settingsItem">
                    <IonLabel position="floating">Old Password</IonLabel>
                    <IonInput name="oldPassword" type="password" />
                  </IonItem>
                  <IonItem className="settingsItem">
                    <IonLabel position="floating"> New Password</IonLabel>
                    <IonInput name="newPassword" type="password" />
                  </IonItem>
                  <IonItem className="settingsItem">
                    <IonLabel position="floating">Confirm New Password</IonLabel>
                    <IonInput name="newPassword2" type="password" />
                  </IonItem>
                  <IonButton className="settings-button" expand="block" type="submit">
                    Save
                  </IonButton>
                </div>
              </form>
            </div>
          </IonCard>
        </div>
      </IonContent>
      <Footer />
    </IonPage>
  );
};

export default withRouter(Account);

import './subscriptions.scss';

import { IonContent, IonPage } from '@ionic/react';
import React, { useState } from 'react';
import SubCard, { Product } from '../../components/subscription-card/subcard';

import Footer from '../../components/footer/footer';
import FullPageSpinner from '../../components/full-page-spinner/fullPageSpinner';
import Header from '../../components/header/header';
import useProducts from '../../hooks/useProducts';

const Subscriptions: React.FC = () => {
  const products = useProducts() as Product[];

  const [isLoading, setIsLoading] = useState(false);

  return (
    <IonPage>
      <Header />
      <IonContent className="screen-bg" fullscreen={true}>
        {!products || products.length < 1 ? (
          <FullPageSpinner />
        ) : (
          <div className="cardContainer">
            {products
              .filter((product: Product) => !product.metadata.hide)
              .sort((a: any) => {
                if (a.id === 'prod_I86XKr4jsBLkiu') {
                  return 1;
                }
                if (a.id === 'prod_I86TTOnvQ6lxcr') {
                  return 1;
                }
                return -1;
              })
              .map((product: Product, idx: number) => (
                <SubCard disabled={isLoading} key={idx} product={product} onIsLoadingChange={setIsLoading} />
              ))}
          </div>
        )}
      </IonContent>
      <Footer />
    </IonPage>
  );
};

export default Subscriptions;

import './adminAddPackageModal.scss';

import { IonButton, IonIcon, IonInput, IonItem, IonLabel, IonModal, IonSelect, IonSelectOption, IonToast } from '@ionic/react';
import React, { useCallback, useState } from 'react';

import AWS from 'aws-sdk';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Product } from '../subscription-card/subcard';
import { close } from 'ionicons/icons';
import firebase from 'firebase';
import { useDropzone } from 'react-dropzone';
import useProducts from '../../hooks/useProducts';

const formatBytes = (bytes: any, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

type AdminAddPackageModalProps = { open: boolean; closeModal?: any };

const AdminAddPackageModal: React.FC<AdminAddPackageModalProps> = ({ open, closeModal }) => {
  const products = useProducts() as Product[];
  const [file, setFile] = useState<File | null>(null);
  const [percentLoaded, setPercentLoaded] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [version, setVersion] = useState('');
  const [productId, setProductId] = useState('');
  const [platform, setPlatform] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [errorToastState, setErrorToastState] = useState(false);

  const onDrop = useCallback(acceptedFiles => {
    setFile(acceptedFiles[0]);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const clearFile = () => {
    setFile(null);
  };

  const validateFormInputs = () => {
    if (!file) {
      setErrorMessage('A file is required to upload');
      setErrorToastState(true);
      return;
    } else if (version === '') {
      setErrorMessage('A version is required to upload');
      setErrorToastState(true);
      return;
    } else if (platform === '') {
      setErrorMessage('A platform type is required to upload');
      setErrorToastState(true);
      return;
    } else if (productId === '') {
      setErrorMessage('A product ID is required to upload');
      setErrorToastState(true);
      return;
    }
    if (platform === 'Windows' && file.name.indexOf('.exe.zip') === -1) {
      setErrorMessage('For a windows upload, the file must end with ".exe.zip"');
      setErrorToastState(true);
      return;
    }
    if (platform === 'Mac' && file.name.indexOf('.app.zip') === -1) {
      setErrorMessage('For a mac upload, the file must end with ".app.zip"');
      setErrorToastState(true);
      return;
    }
    uploadFile();
  };
  const accessKeyId: any = process.env.REACT_APP_AWS_ACCESS_KEY;
  const secretAccessKey: any = process.env.REACT_APP_AWS_S_KEY;

  const uploadFile = () => {
    if (!file) return;
    const db = firebase.firestore();
    const credentials = new AWS.Credentials({
      accessKeyId: accessKeyId,
      secretAccessKey: secretAccessKey
    });
    AWS.config.update({
      region: 'us-east-2',
      credentials
    });
    const S3 = new AWS.S3();
    const objParams = {
      Bucket: 'vxmed',
      Key: `binaries/${file.name}`,
      Body: file,
      ACL: 'public-read',
      ContentType: file.type
    };
    setUploading(true);
    S3.upload(objParams)
      .on('httpUploadProgress', ({ loaded, total }: { loaded: number; total: number }) => {
        setPercentLoaded(Math.round((loaded / total) * 100));
      })
      .send((err: any, data: any) => {
        if (err) {
          setErrorMessage(err.code + ': ' + err.message);
          setErrorToastState(true);
          setUploading(false);
        } else {
          db.collection('packages/' + productId + '/dist/')
            .add({
              file_name: file.name,
              file_url: data.Location,
              version: version,
              product_id: productId,
              platform: platform
            })
            .catch(error => {
              console.error('Error adding document: ', error);
            });
          setUploading(false);
          closeModal();
        }
      });
  };

  const setFormValue = (event: any) => {
    if (event.target.name === 'version') {
      setVersion(event.detail.value);
    } else if (event.target.name === 'productId') {
      setProductId(event.detail.value);
    }
  };

  const platformSelection = (event: any) => {
    setPlatform(event.target.value);
  };

  const closeErrorToast = () => {
    setErrorToastState(false);
    setErrorMessage('');
  };

  return (
    <IonModal isOpen={open} onDidDismiss={closeModal}>
      <IonToast isOpen={errorToastState} onDidDismiss={closeErrorToast} message={errorMessage} cssClass="errorToastUploadModal" position="middle" duration={4000} />
      <div className="uploadModal">
        {uploading ? (
          <div className="uploadProgressBarContainer">
            <LinearProgress className="progressBar" variant="determinate" value={percentLoaded} />
          </div>
        ) : (
          <>
            <IonIcon icon={close} onClick={closeModal} className="modalCloseButton" />

            <div className="modalBody">
              <div className="uploadArea">
                {file !== null ? (
                  <div className="uploadDataContainer">
                    <p>File: {file.name}</p>
                    <p>Size: {formatBytes(file.size)}</p>
                    <IonButton onClick={clearFile}>Clear file</IonButton>
                  </div>
                ) : (
                  <div className="uploadSystem" {...getRootProps()}>
                    <input {...getInputProps()} />
                    {isDragActive ? <p className="uploadAreaText">Drop the files here ...</p> : <p className="uploadAreaText">Drag & drop files here, or click to select files</p>}
                  </div>
                )}
              </div>
              <div className="versionPlatformRow">
                <IonItem className="rowItem">
                  <IonLabel position="floating">Version</IonLabel>
                  <IonInput name="version" type="text" onIonChange={setFormValue} />
                </IonItem>
                <IonItem className="rowItem">
                  <IonLabel>Platform</IonLabel>
                  <IonSelect okText="Okay" cancelText="Dismiss" onIonChange={platformSelection}>
                    <IonSelectOption value="Mac">Mac</IonSelectOption>
                    <IonSelectOption value="Windows">Windows</IonSelectOption>
                  </IonSelect>
                </IonItem>
              </div>
              <IonItem className="loginItem">
                <IonLabel position="floating">Product ID</IonLabel>
                <IonSelect okText="Okay" cancelText="Dismiss" name="productId" onIonChange={setFormValue}>
                  {products.map((p: any, i) => {
                    return (
                      <IonSelectOption key={i} value={p.id}>
                        {p.name}
                      </IonSelectOption>
                    );
                  })}
                </IonSelect>
              </IonItem>
            </div>
            <p style={{ color: 'red', margin: 14 }}>
              ** Ensure the upload is a .zip file and that the un-zipped executable file has the same name. Example: VxMed-1.3.4.app.zip should be uploaded and when its unzipped,
              the launcher will launch VxMed-1.3.4.app (or .exe for windows)
            </p>

            <IonButton onClick={validateFormInputs}>Upload</IonButton>
          </>
        )}
      </div>
    </IonModal>
  );
};

export default AdminAddPackageModal;
